<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-10-07 19:18 -->
<!-- Description:  -->
<template>
  <div class="input-group">
    <div class="input-group-prepend">
      <input
        type="number"
        step="1"
        class="form-control"
        v-model="year"
        @change="autoSetRange()"
      />
    </div>
    <select
      class="custom-select"
      id="inputGroupSelect01"
      v-model="month"
      @change="autoSetRange()"
    >
      <option :value="1">ENERO</option>
      <option :value="2">FEBRERO</option>
      <option :value="3">MARZO</option>
      <option :value="4">ABRIL</option>
      <option :value="5">MAYO</option>
      <option :value="6">JUNIO</option>
      <option :value="7">JULIO</option>
      <option :value="8">AGOSTO</option>
      <option :value="9">SEPTIEMBRE</option>
      <option :value="10">OCTUBRE</option>
      <option :value="11">NOVIEMBRE</option>
      <option :value="12">DICIEMBRE</option>
    </select>
  </div>

  <!-- <div class="row"> -->
  <!--   <div class="col"> -->
  <!--     <input class="form-control" type="date" v-model="date_ini" /> -->
  <!--   </div> -->
  <!--   <div class="col"> -->
  <!--     <input class="form-control" type="date" v-model="date_end" /> -->
  <!--   </div> -->
  <!-- </div> -->
</template>
<script>
import myMoment from "../../lt/utils/myMoment.js";
import { momentDateFormat } from "../../utils/myMoment";
// script content
export default {
  name: "SelectDateRange",
  components: {
    //
  },

  // directives

  props: {
    //
  },

  data: () => ({
    date_end: null,
    date_ini: null,
    year: null,
    month: null,
  }),

  computed: {
    //
  },

  // watch: {},

  created() {
    let dateNow = myMoment();
    this.year = dateNow.year();
    this.month = dateNow.month() + 1;
    this.date_ini = dateNow.format("YYYY-MM-[01]");
    this.date_end = dateNow
      .date(1)
      .add(1, "month")
      .add(-1, "day")
      .format(momentDateFormat);
  },

  mounted() {
    //
  },

  methods: {
    getRange() {
      return [this.date_ini, this.date_end];
    },
    autoSetRange() {
      let dateRelative = myMoment();
      dateRelative.month(this.month - 1);
      dateRelative.year(this.year);
      // dateRelative.date(1);
      this.date_ini = dateRelative.format("YYYY-MM-[01]");
      this.date_end = dateRelative
        .date(1)
        .add(1, "month")
        .add(-1, "day")
        .format(momentDateFormat);
    },
    //
    //
  },
};
</script>
<style scoped></style>
