<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-10-07 23:49 -->
<!-- Description:  -->
<template>
  <div>
    <canvas ref="elCanvas"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";
// script content
export default {
  name: "LineChart",
  components: {
    //
  },

  // directives

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    generate(data) {
      // const data = [
      //   { year: 2010, count: 10 },
      //   { year: 2011, count: 20 },
      //   { year: 2012, count: 15 },
      //   { year: 2013, count: 25 },
      //   { year: 2014, count: 22 },
      //   { year: 2015, count: 30 },
      //   { year: 2016, count: 28 },
      // ];

      new Chart(this.$refs.elCanvas, {
        type: "line",
        data,
        // data: {
        //   labels: data.map((row) => row.year),
        //   datasets: [
        //     {
        //       label: "Acquisitions by year",
        //       data: data.map((row) => row.count),
        //     },
        //   ],
        // },
      });
    },
    //
    //
  },
};
</script>
<style scoped></style>
